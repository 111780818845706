import { inject } from '@angular/core';
import { TaskLoadedSuccessfully, TaskLoadingStarted } from '@app/store';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import {
  filter, finalize,
  first, map, tap,
} from 'rxjs/operators';

export const apiEntityResolver = <T>(
  checkedEntity$: Observable<T | undefined>,
) => {
  const store = inject(Store);

  return checkedEntity$.pipe(
    tap((checkedEntity) => {
      if (checkedEntity === undefined) {
        store.dispatch(new TaskLoadingStarted());
      }
    }),
    filter((checkedEntity) => checkedEntity !== undefined),
    map(() => true as const),
    first(),
    finalize(() => store.dispatch(new TaskLoadedSuccessfully())),
  );
};
